import React from "react";
import { Box, Dialog, Typography, Button } from "@mui/material";
import Layout from "./Layout";
import Logo from "./Logo";
import String from "./String";
import { useTheme } from "../contexts/theme";
import { useExperience } from "../contexts/experience"

export default function AdminControls({ show, onClose }) {

  const { theme } = useTheme();

  const exp = useExperience();

  const hardReset = () => {
    exp.destroy();
    window.location = "/";
  }

  const testAudio = () => {
    exp.test();
  }

  return (
    <Dialog fullScreen open={show} className={"lock " + theme}>
      <Layout
        top={
          <Box mt={7} textAlign="center">
            <Logo />
          </Box>
        }
      >
        <Box textAlign="center" style={{ position: 'relative' }}>
          <Typography variant="title" mb={2}>
            <String s="app_name" />
          </Typography>
          <Box mb={2}>
            <Button color={theme === 'night' ? 'primary' : 'secondary'} variant="contained" fullWidth onClick={hardReset}>
              <String s="admin_reset_hard" />
            </Button>
          </Box>
          <Box mb={2}>
            <Button color={theme === 'night' ? 'primary' : 'secondary'} variant="contained" fullWidth onClick={testAudio}>
              <String s="admin_test" />
            </Button>
          </Box>
          <Box mb={2}>
            <Button color={theme === 'night' ? 'primary' : 'secondary'} variant="contained" fullWidth onClick={onClose}>
              <String s="admin_close" />
            </Button>
          </Box>
        </Box>
      </Layout>
    </Dialog>
  );
}