const experience = {
  patterns: [
    {
      id: "memoria-exp",
      label: "Pattern default Memoria San Giuliano",
      spots: [
        {
          id: "s00",
          label: "La Romagna",
          position: "p00",
          audio: "a00",
        },
        {
          id: "s01",
          label: "Le Donne",
          position: "p01",
          audio: "a01",
        },
        {
          id: "s02",
          label: "Mirteto",
          position: "p02",
          audio: "a02"
        },
        {
          id: "s03",
          label: "Parco Partigiano",
          position: "p03",
          audio: "a03"
        },
      ]
    }
  ],
  options: {
    cookies: {
      deleteOnCompletion: false,
      deleteOnLastSpot: true, // workaround for bugged library
      expiration: 30
    }
  }
};

export default experience;
