import React from "react";

import ReactDOM from "react-dom/client";

import reportWebVitals from './reportWebVitals';

import { toast } from "react-toastify";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import * as Sentry from "@sentry/react";

import { Integrations } from "@sentry/tracing";

import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

import App from "./App";

Sentry.init({
  dsn: "https://ca3ae860a24944ca8e3717fbbfe889c6@o1100855.ingest.sentry.io/4504078748024832",
  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsoleIntegration({ levels: ['warn', 'error', 'debug', 'assert'] })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onSuccess: () => {
    toast.success('App installata correttamente', {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: {
        backgroundColor: '#588157'
      }
    });
  },
  onUpdate: () => {
    toast.info('È disponibile una nuova versione. Chiudi e riapri l\'app per aggiornare', {
      position: "bottom-center",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: {
        backgroundColor: '#005595'
      }
    });
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);