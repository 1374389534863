import React, { useEffect } from "react";

import { MapContainer, Marker, useMap } from "react-leaflet";

import Player from "../components/Player";

import { useTheme } from "../contexts/theme";

import { useExperience } from "../contexts/experience";

import { CircularProgress } from "@mui/material";

import GpsIcon from '@mui/icons-material/GpsFixedSharp';

import marker_day from "../assets/svg/day/marker.svg";

import marker_day_active from "../assets/svg/day/marker_active.svg";

import marker_night from "../assets/svg/night/marker.svg";

import marker_night_active from "../assets/svg/night/marker_active.svg";

import pos_marker_day from "../assets/svg/day/position.svg";

import pos_marker_night from "../assets/svg/night/position.svg";

import String from "./String";

import L from "leaflet";

import "leaflet/dist/leaflet.css";

import "leaflet.offline";

export default function Map() {

  const { theme } = useTheme();

  const { spots, spotIndex, active, position } = useExperience();

  const pos = position?.coords && [
    position.coords.latitude,
    position.coords.longitude,
  ];

  const markers = {
    day: { default: marker_day, active: marker_day_active },
    night: { default: marker_night, active: marker_night_active },
  };

  const pos_markers = {
    day: pos_marker_day,
    night: pos_marker_night,
  };

  const removeAttribution = (map) => {
    map.attributionControl._container.style = 'display: none';
  }

  const marker = (active) =>
    new L.Icon({
      iconUrl: markers[theme][active ? "active" : "default"],
      popupAnchor: [-0, -0],
      iconSize: [32, 45],
      iconAnchor: [15, 45],
    });
  const position_marker = () =>
    new L.Icon({
      iconUrl: pos_markers[theme],
      iconSize: [16, 16],
    });

  return !pos ? (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <span style={{ display: 'inline-flex', marginBottom: '20px' }}>
        <String s="search_gps" />
        <span><GpsIcon style={{ marginLeft: '8px' }} /></span>
      </span>
      <CircularProgress />
    </div>
  ) : (
    <div style={{ height: "100%", width: "100%" }}>
      {active && (
        <div style={{ width: "100%", zIndex: 2 }}>
          <Player spot={active} index={spotIndex} />
        </div>
      )}
      <MapContainer
        style={{ height: "100%", zIndex: 0, background: "black" }}
        bounds={spots.map((s) => [s.lat, s.lon])}
        zoom={50}
        scrollWheelZoom={false}
        zoomControl={false}
        dragging={true}
        whenCreated={removeAttribution}
      >
        <Accuracy />

        {theme === "night" ? (
          <>
            <Tile theme="day" />
            <Tile theme="night" />
          </>
        ) : (
          <>
            <Tile theme="night" />
            <Tile theme="day" />
          </>
        )}

        {spots &&
          spots.map((spot) => (
            <Marker
              key={spot.id}
              icon={marker(active && spot.id === active.position)}
              position={[spot.lat, spot.lon]}
            />
          ))}
        {pos && (
          <Marker
            key="position"
            icon={position_marker()}
            position={pos}
            zIndexOffset={999}
          />
        )}
      </MapContainer>
    </div>
  );
}

function Tile({ theme }) {
  const map = useMap();
  useEffect(() => {
    const tiles = {
      day: {
        attribution:
          '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        url: `https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png`,
      },
      night: {
        attribution:
          '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        url: `https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png`,
      },
    };
    const tile = tiles[theme];
    const offline = L.tileLayer
      .offline(tile.url, {
        attribution: tile.attribution,
        subdomains: '',
        minZoom: 5,
      })
      .addTo(map);
    return () => offline.removeFrom(map);
  }, [theme, map]);
  return null;
}

function Accuracy() {
  const { position } = useExperience();
  const { theme } = useTheme();
  const accuracy = position?.coords?.accuracy;
  const style = {
    position: "absolute",
    bottom: "0px",
    color: theme === "day" ? "#000" : "#fff",
    padding: "7px 15px",
    zIndex: 999,
    opacity: 0.7,
    fontSize: "10px",
  };
  return accuracy ? (
    <span style={style}>
      <strong>Accuracy: </strong>
      {accuracy.toFixed(2)}m
    </span>
  ) : null;
}
