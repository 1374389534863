import React from "react";

import { useTheme } from "../contexts/theme";

const svgDir = require.context("!@svgr/webpack!../assets/svg", true);

// TODO - fix this
const svgSrc = (path) => require("../assets/svg" + path.replace(".", ""));

export default function Image({ i, src, ...p }) {

  const { image_dir } = useTheme();

  if (i === 'placeholder') {
    return <img src="https://via.placeholder.com/150?text=logo" {...p} />;
  } 

  const file = `/${i}.svg`;

  const path = svgDir.keys().indexOf(image_dir + file) >= 0
    ? image_dir + file
    : `.${file}`;

  // const _src = svgSrc(path).default;
  const _src = `../assets/svg${path?.replace('.', '')}`;

  return <img src={_src} {...p} />;
}
