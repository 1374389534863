import React, { useEffect, useState } from "react";

import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import PreviousIcon from "@mui/icons-material/FastRewind";

import NextIcon from "@mui/icons-material/FastForward";

import { useExperience } from "../contexts/experience";

import palette from "../config/themes/palette";

export default function Player({ spot }) {

  const [progress, setProgress] = useState(0);
  
  const { player, spotIndex } = useExperience();

  const audio = player?.audio;
  const duration = audio?._duration;
  const perc = (progress / duration) * 100;
  const elapsed = formatTimer(progress);
  const countdown = formatTimer(duration - progress);

  useEffect(() => {
    const seek = setInterval(() => {
      const _p = audio?.seek();
      setProgress(_p);
    }, 100);
    return () => clearInterval(seek);
  }, [audio]);

  const skip = (s) => {
    const _s = progress + s;
    if (_s > 0 && _s < duration) audio?.seek(_s);
  };

  return (
    <Card style={{ textAlign: "center" }}>
      <Box mt={3} mb={2}>
        <Avatar style={labelStyle}>{spotIndex || 1}</Avatar>
        <Typography variant="h5" mt={1}>
          {spot.label}
        </Typography>
      </Box>
      <Box mb={3}>
        <Grid
          container
          justify="space-around"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          {/* <Grid item>
            <IconButton onClick={() => skip(-10)}>
              <PreviousIcon style={{ fontSize: "10vw" }} />
            </IconButton>
            <Typography fontSize={"3vw"} color="textSecondary" mt={1}>
              10 s
            </Typography>
          </Grid> */}
          <Grid item>
            <IconButton
              // onClick={() => (audio?.playing() ? audio.pause() : audio.play())}
              style={btnStyle}
            >
              <Box style={countDownStyle}>
                <Typography fontSize={"5vw"} fontWeight="bold">
                  {elapsed.mm}:{elapsed.ss}
                </Typography>
              </Box>
              <CircularProgress
                variant="determinate"
                value={Math.floor(perc)}
                style={progressStyle}
                thickness={2}
              />
            </IconButton>
            {/* <Typography fontSize={"3vw"} color="textSecondary" mt={1}>
              {elapsed.mm}:{elapsed.ss}
            </Typography> */}
          </Grid>
          {/* <Grid item>
            <IconButton onClick={() => skip(10)}>
              <NextIcon style={{ fontSize: "10vw" }} />
            </IconButton>
            <Typography fontSize={"3vw"} color="textSecondary" mt={1}>
              10 s
            </Typography>
          </Grid> */}
        </Grid>
      </Box>
    </Card>
  );
}

// const formatTimer = (t) => {
//   const _t = t || 0;
//   let mm = Math.floor(_t / 60);
//   let ss = Math.floor(_t);
//   mm = mm < 10 ? "0" + mm : mm;
//   ss = ss < 10 ? "0" + ss : ss;
//   return { mm, ss };
// };

const formatTimer = (t) => {
  if (t > 0) {
    const o = new Date(0);
    const p = new Date(t * 1000);
    const array = new Date(p.getTime() - o.getTime())
      .toISOString()
      .split('T')[1]
      .split('Z')[0]
      .split(':');
    return {
      mm: array[1],
      ss: array[2].split('.')[0]
    };
  } else {
    return { mm: "00", ss: "00" };
  }
};

const btnStyle = {
  position: "relative",
  border: "2px solid",
  height: "22vw",
  width: "22vw",
};
const countDownStyle = {
  position: "absolute",
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
};
const progressStyle = { position: "absolute", height: "107%", width: "107%" };
const labelStyle = {
  background: palette.glade_green,
  height: "20px",
  width: "20px",
  margin: "auto",
  fontSize: "12px",
  fontWeight: "bold",
};
