import React from "react";

import {
  Route,
  Switch,
  useLocation,
  matchPath,
  Redirect,
} from "react-router-dom";

import { ViewTransition } from "./Transition";

export default function Routes({ views, ...p }) {
  return (
    <ViewSwitcher>
      {views.map((view) => (
        <Route key={view.path} {...view} />
      ))}
      <Redirect to="/boarding/1" />
    </ViewSwitcher>
  );
}

function ViewSwitcher({ children }) {
  const location = useLocation();
  let key;
  React.Children.forEach(children, (child) => {
    if (child && matchPath(location.pathname, child.props) && !key) {
      key = child.props.path;
    }
  });
  return (
    <ViewTransition _key={key}>
      <Switch key={key} location={location}>
        {children}
      </Switch>
    </ViewTransition>
  );
}
