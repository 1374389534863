const en = {
  lang: "English",
  select_lang: "Choose your language",
  day: "Day",
  night: "Night",
  confirm: "Confirm",
  continue: "Continue",
  yes_continue: "Yes, continue",
  start: "Start",
  back: "Back",
  wait: "Plase wait...",
  headset: "Please use the provided headset for a better experience",
  raise_volume: "Turn up your device's volume",
  sound: "Can you hear the sound correctly?",
  wait_location: "Verify GPS signal",
  gps: "The app is trying to get your location",
  gps_error: "There's a problem with this phone GPS",
  gps_quality_bad: "POOR SIGNAL",
  gps_quality_average: "ACCEPTABLE SIGNAL",
  gps_quality_good: "EXCELLENT SIGNAL",
  cookies: "There's a tour already in progress",
  cookies_action: "Would you like to continue or to start it again?",
  cookies_delete: "New tour",
  cookies_maintain: "Continue tour",
  unlock: 'Confirm unlock?',
  unlock_subtitle: 'Unlocking the screen is for admins only',
  areyouready: "Ready to begin the tour?",
  welcome: "WELCOME",
  beforestart: "Before the boat departs, click on Start. The experience will start on its own, so you can enjoy the tour without reopening the app.",
  map: "Map",
  info: "Info",
  mobile_only: "This App was designed for mobile devices",
  screen_locked: "Screen locked",
  swipe_left: "Swipe left to unlock",
  admin_title: "Admin panel",
  admin_close: "Close",
  admin_test: "Test audio",
  admin_reset_hard: "Home",
  search_gps: "Searching for GPS signal",
  credits_title: "Sounds of Memory",
  credits_desc: "Sounds of Memory is an interactive installation that came into being as a creation within the Memory Project, a journey begun in the 1990s of the 20th century by the Municipality of San Giuliano Terme, with the aim of preserving and reviving the historical memory of the events and people of the San Giuliano territory during World War II. The Memory Project is managed by the Teatri della Resistenza Association, which is the driving force behind this modernization of the Memory narrative, in collaboration with the Council Department of Memory and the appointed offices. Sounds of Memory is a sound interaction about the memories and voices that have marked the San Giuliano area and made it what it is today: a place of democracy.",
  app_name: "Sounds of Memory",
  how_it_works: "How it works?",
  app_explanation: "For this experience we created some “active spots”, which associate a location in space with an audio content. Content playback is based on your location: the system locates where you are and activates (or deactivates) a content the moment you enter (or leave) its range. You can keep track of your location and the active spots from the map: get closer and enjoy!",
  project_by: "Project by:",
  sponsored_by: "Sponsored by:",
  sound_design_app_dev: "Sound design and app development:",
  narrator: "Texts and voice:",
  translator: "English version:",
  already_visited_title: "You've already visited this spot recently",
  already_visited_text: "Do you want to play to the audio content again?",
  already_visited_confirm: "Yes, play",
  already_visited_dismiss: "No, close",
};

export default en;
