import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Grid,
} from '@mui/material';

import String from './String';

import { useExperience } from '../contexts/experience';

import { useTheme } from '../contexts/theme';

export function VisitedSpotAlert() {

  const {
    alreadyVisitedId,
    setAlreadyVisitedId,
    replayVisited
  } = useExperience();

  const { theme } = useTheme();

  const dialogStyle =
    theme === 'day'
      ? {
        border: "1px solid #191A1C11"
      }
      : {
        border: "1px solid white"
      };

  return (
    <Dialog
      open={alreadyVisitedId !== null}
      onClose={() => setAlreadyVisitedId(null)}
      PaperProps={{
        style: dialogStyle
      }}
    >
      <DialogTitle>
        <String s="already_visited_title" />
      </DialogTitle>

      <DialogContent>
        <DialogContentText style={{ fontWeight: "bold" }}>
          <String s="already_visited_text" />
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Grid
          container
          rowSpacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Button
              color={'primary'}
              variant="contained"
              fullWidth
              onClick={replayVisited}
            >
              <String s="already_visited_confirm" />
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              color={'secondary'}
              variant="contained"
              fullWidth
              onClick={() => setAlreadyVisitedId(null)}
            >
              <String s="already_visited_dismiss" />
            </Button>
          </Grid>

        </Grid>
      </DialogActions>
    </Dialog>
  );
}