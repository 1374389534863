import React, { useState } from "react";

import { AppBar, Toolbar } from "@mui/material";

import AdminControls from './AdminControls';

import logoComune from "../assets/img/logo_san_giuliano.png";

import { useTheme } from "../contexts/theme";

export default function Header() {

  const [showAdmin, setShowAdmin] = useState(false);

  const { theme } = useTheme();

  const logoSrc = theme === 'night'
    ? '/assets/img/logo512_transp.png'
    : '/assets/img/logo512_transp_white.png';

  return (
    <AppBar position="static">
      <Toolbar style={toolbar}>
        <div
          onClick={() => setShowAdmin(true)}
          style={{ display: 'flex' }}
        >
          <img
            alt="Suoni della Memoria"
            src={logoSrc}
            width="60px"
          />
        </div>

        <img alt="San Giuliano Terme" src={logoComune} width={"13%"} />
      </Toolbar>
      <AdminControls show={showAdmin} onClose={() => setShowAdmin(false)} />
    </AppBar>
  );
}

const toolbar = {
  justifyContent: "space-between",
  paddingTop: "10px",
  paddingBottom: "10px",
};
