import React from "react";

import { BrowserRouter as Router } from "react-router-dom";

import Routes from "./components/Routes";

import Theme from "./contexts/theme";

import Language from "./contexts/language";

import Boarding from "./views/Boarding";

import Login from "./views/Login";

import Tour from "./views/Tour";

import "./css/App.css";

import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  return (
    <Contexts>
      <Routes test="root" views={views} />
    </Contexts>
  );
}

function Contexts({ children }) {
  return (
    <Language>
      <Theme>
        <Router>{children}</Router>
      </Theme>
    </Language>
  );
}

const views = [
  {
    name: "Boarding",
    path: ["/boarding"],
    component: Boarding,
  },
  {
    name: "Tour",
    path: "/tour",
    component: Tour,
  },
];
