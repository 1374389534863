import React from "react";

export default function Logo({ ...p }) {

  return <img
    src="/assets/img/logo_transp_horizontal.png"
    style={{ width: "120px" }}
    {...p}
  />;
}
