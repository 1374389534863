import laRomagnaUrl from '../../assets/audio/it/La_Romagna.mp3';
import leDonneUrl from '../../assets/audio/it/Le_donne.mp3';
import mirtetoUrl from '../../assets/audio/it/Mirteto.mp3';
import parcoPartigianoUrl from '../../assets/audio/it/Parco_Partigiano.mp3';

const it = {
  audio0: {
    url: laRomagnaUrl,
    label: 'La Romagna'
  },
  audio1: {
    url: leDonneUrl,
    label: 'Le Donne'
  },
  audio2: {
    url: mirtetoUrl,
    label: 'Mirteto'
  },
  audio3: {
    url: parcoPartigianoUrl,
    label: 'Parco Partigiano'
  },
};

export default it;