const geo = {
  positions: [
    {
      id: "p00",
      label: "La Romagna",
      lon: 10.437410536860956,
      lat: 43.80569771659845,
      radius: 30,
      deadband: 150,
    },
    {
      id: "p01",
      label: "Le Donne (Comune)",
      lon: 10.443750,
      lat: 43.763750,
      radius: 30,
      deadband: 150,
    },
    {
      id: "p02",
      label: "Mirteto (Cisternone)",
      lon: 10.477946,
      lat: 43.750682,
      radius: 30,
      deadband: 150,
    },
    {
      id: "p03",
      label: "Parco Partigiano",
      lon: 10.445747218934107,
      lat: 43.735859538873584,
      radius: 30,
      deadband: 150,
    }
  ],
  options: {
    enableHighAccuracy: true,
    accuracy: 50,
    defaultDeadband: 150,
    defaultRadius: 30,
    defaultFetch: 1,
  }
};

export default geo;
