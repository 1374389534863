import {
  Grid,
  Box,
  Typography
} from "@mui/material";

import String from "./String";

import logoMezzoforteDay from "../assets/img/mezzoforte_day.png";
import logoMezzoforteNight from "../assets/img/mezzoforte_night.png";
import logoTdrDay from "../assets/img/TDR_day.png";
import logoTdrNight from "../assets/img/TDR_night.png";
import logoComune from "../assets/img/logo_san_giuliano.png";

import activeSpotIta from "../assets/img/active_spot_ita.png";
import activeSpotEng from "../assets/img/active_spot_eng.png";

import { useTheme } from "../contexts/theme";
import { useLanguage } from "../contexts/language";

function CreditsLogo({ logo, width }) {

  const { theme } = useTheme();

  const imgStyle = {
    width: width || 50
  };

  switch (logo) {
    case 'tdr':
      return (theme === "day")
        ? <img alt="Teatri della Resistenza" style={imgStyle} src={logoTdrDay} />
        : <img alt="Teatri della Resistenza" style={imgStyle} src={logoTdrNight} />;
    case 'mezzoforte':
      return (theme === "day")
        ? <img alt="logo-mezzoforte" style={imgStyle} src={logoMezzoforteDay} />
        : <img alt="logo-mezzoforte" style={imgStyle} src={logoMezzoforteNight} />;
    case 'comune':
    default:
      return <img alt="logo-comune" style={imgStyle} src={logoComune} />;
  }
}

function AlignedRow({ children }) {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }}>
      {children}
    </div>
  )
}

function InfoAndCredits() {

  const { language } = useLanguage();

  return (
    <>
      <Box maxWidth={"85vw"} style={{ paddingTop: 30 }}>
        <Typography variant="title" style={{ marginBottom: 15 }}>
          <String s="credits_title" />
        </Typography>
        <Typography variant="text" style={{ marginBottom: 15 }}>
          <String s="credits_desc" />
        </Typography>
      </Box>

      <Box maxWidth={"85vw"} style={{ paddingTop: 30 }}>
        <Typography variant="subtitle" style={{ marginBottom: 15 }}>
          <String s="how_it_works" />
        </Typography>
        <Typography variant="text" style={{ marginBottom: 15 }}>
          <String s="app_explanation" />
        </Typography>

        {language === "en"
          ? <img alt="active-spot" width="100%" src={activeSpotEng} />
          : <img alt="active-spot" width="100%" src={activeSpotIta} />}
      </Box>

      <Box maxWidth={"85vw"} style={{ marginTop: 60, paddingBottom: 30 }}>

        <Typography variant="pretitle" >
          Credits
        </Typography>

        <Grid container rowSpacing={4} justifyContent="center" alignItems="center">

          <Grid item xs={12}>
            <AlignedRow>
              <Typography variant="text" style={{ marginRight: 15 }}>
                <String s="project_by" />
              </Typography>
              <CreditsLogo logo="tdr" width={160} />
            </AlignedRow>
          </Grid>

          <Grid item xs={12}>
            <AlignedRow>
              <Typography variant="text" style={{ marginRight: 15 }}>
                <String s="narrator" />
              </Typography>
              <Typography variant="subtitle" style={{ marginRight: 15 }}>
                Dario Focardi
              </Typography>
            </AlignedRow>
          </Grid>

          <Grid item xs={12}>
            <AlignedRow>
              <Typography variant="text" style={{ marginRight: 15 }}>
                <String s="sound_design_app_dev" />
              </Typography>
              <CreditsLogo logo="mezzoforte" width={100} />
            </AlignedRow>
          </Grid>

          <Grid item xs={12}>
            <AlignedRow>
              <Typography variant="text" style={{ marginRight: 15 }}>
                <String s="translator" />
              </Typography>
              <Typography variant="subtitle" style={{ marginRight: 15 }}>
                Claudia Magnani
              </Typography>
            </AlignedRow>
          </Grid>

          <Grid item xs={12}>
            <AlignedRow>
              <Typography variant="text" style={{ marginRight: 15 }}>
                <String s="sponsored_by" />
              </Typography>
              <CreditsLogo logo="comune" width={110} />
            </AlignedRow>
          </Grid>

        </Grid>

      </Box>
    </>
  )
}

export default InfoAndCredits;