import React from "react";

import { AppBar, Toolbar } from "@mui/material";

export default function Footer({ children }) {
  return (
    <AppBar
      position="static"
      color="transparent"
      style={{
        boxShadow: "0 -3px 5px #191A1C11"
      }}
    >
      <Toolbar
        style={{ justifyContent: "space-around", background: "transparent" }}
      >
        {children}
      </Toolbar>
    </AppBar>
  );
}
