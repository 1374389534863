import React, { useEffect, useState } from "react";

import {
  AppBar,
  Box,
  Button,
  Tabs,
  Tab,
  Typography,
  Backdrop,
} from "@mui/material";

import NoSleep from "nosleep.js";

import Layout from "../components/Layout";

import String from "../components/String";

import ThemeSwitcher from "../components/ThemeSwitcher";

import Header from "../components/Header";

import Footer from "../components/Footer";

import Map from "../components/Map";

import { VisitedSpotAlert } from "../components/VisitedSpotAlert";

import InfoAndCredits from "../components/InfoAndCredits";

import Experience, { useExperience } from "../contexts/experience";

const noSleep = new NoSleep();

export default function Tour() {

  const [start, setStart] = useState(false);

  const [welcomeEnded, setWelcomeEnded] = useState(false);

  useEffect(() => {
    // prevent pull-down-to-refresh
    document.body.classList.add('no-pull-refresh');

    return () => {
      document.body.classList.remove('no-pull-refresh');
      noSleep.disable();
    }
  }, []);

  return (
    <Experience>

      <Layout
        full
        top={<Header />}
        bottom={
          <Footer>
            <ThemeSwitcher />
          </Footer>
        }
      >

        <VisitedSpotAlert />

        <Overlay
          start={start}
          setStart={setStart}
          welcomeEnded={welcomeEnded}
          setWelcomeEnded={setWelcomeEnded}
        />

        <TabsContainer />

      </Layout>
    </Experience>
  );
}

function TabsContainer() {

  const [tab, setTab] = useState("map");

  return (
    <Layout
      full
      top={
        <AppBar position="static" color="transparent">
          <Tabs
            value={tab}
            onChange={(e, t) => setTab(t)}
            variant="fullWidth"
            textColor="inherit"
            style={{
              boxShadow: "0 3px 5px #191A1C11"
            }}
          >
            <Tab
              value="map"
              label={(
                <Typography
                  variant="pretitle"
                >
                  <String s="map" />
                </Typography>
              )}
            />
            <Tab
              value="info"
              label={(
                <Typography
                  variant="pretitle"
                >
                  <String s="info" />
                </Typography>
              )}
            />
          </Tabs>
        </AppBar>
      }
    >
      {tab === "map" && <MapTab />}
      {tab === "info" && <InfoTab />}
    </Layout>
  );
}

function Overlay({ start, setStart }) {

  const { unlock } = useExperience();

  return (
    <Backdrop style={{ position: "absolute", zIndex: 9 }} open={(!start)}>
      <Layout justify="center">
        <Box textAlign="center" ml={4} mr={4}>
          {
            !start &&
            <>
              <Typography variant="title" mb={2}>
                <String s="areyouready" />
              </Typography>
              <Typography variant="text" mb={3}>
                <String s="beforestart" />
              </Typography>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  noSleep.enable();
                  unlock();
                  setStart(true);
                  // stop immediately if cookies are set (means we are in the middle of the tour)
                }}
              >
                <String s="start" />
              </Button>
            </>
          }
        </Box>
      </Layout>
    </Backdrop>
  );
}

function MapTab() {
  return (
    <Layout full>
      <Map />
    </Layout>
  );
}
function InfoTab() {
  return (
    <Layout justify="start" full>
      <InfoAndCredits />
    </Layout>
  );
}
