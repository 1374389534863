import it from "./it";

import en from "./en";

import { default_language } from "../languages";

const languages = { it, en };

export default function audio(language = default_language) {
  const selectedLangAudios = languages[language];
  return {
    sounds: [
      {
        id: "a00",
        label: selectedLangAudios.audio0.label,
        url: selectedLangAudios.audio0.url,
        format: "aac",
      },
      {
        id: "a01",
        label: selectedLangAudios.audio1.label,
        url: selectedLangAudios.audio1.url,
        format: "aac",
      },
      {
        id: "a02",
        label: selectedLangAudios.audio2.label,
        url: selectedLangAudios.audio2.url,
        format: "aac",
      },
      {
        id: "a03",
        label: selectedLangAudios.audio3.label,
        url: selectedLangAudios.audio3.url,
        format: "aac",
      },
    ],
  };
}
