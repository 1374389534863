import React, { useState } from "react";

import { Link } from "react-router-dom";

import Layout from "../components/Layout";

import {
  Box,
  TextField,
  Button,
} from "@mui/material";

const PWD = 'memoriageoxp';

function Login() {

  const [pwd, setPwd] = useState('');

  return (
    <Layout>
      <Box textAlign="center" mb={"5vh"} width="90%">

        <TextField
          placeholder="Inserisci la password"
          fullWidth
          value={pwd}
          multiline={false}
          type="password"
          onChange={e => setPwd(e.target.value)}
          sx={{
            marginBottom: '10px'
          }}
        />

        <Button
          color="secondary"
          variant="contained"
          component={Link}
          to={"/boarding/1"}
          disabled={pwd !== PWD}
          fullWidth
        >
          ENTRA
        </Button>
      </Box>
    </Layout>
  );
}


export default Login;