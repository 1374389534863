const it = {
  lang: "Italiano",
  select_lang: "Scegli la lingua",
  day: "Giorno",
  night: "Notte",
  confirm: "Conferma",
  continue: "Continua",
  yes_continue: "Si, continua",
  start: "Inizia",
  back: "Indietro",
  wait: "Attendi...",
  headset: "Indossa le cuffie in dotazione per sentire l'audio correttamente",
  raise_volume: "Aumenta il volume dello smartphone",
  sound: "Stai sentendo l'audio correttamente?",
  wait_location: "Verifica il segnale GPS",
  gps: "L'app sta cercando di capire dove ti trovi",
  gps_error: "C'è un problema con il GPS di questo telefono",
  gps_quality_bad: "SEGNALE SCARSO",
  gps_quality_average: "SEGNALE ACCETTABILE",
  gps_quality_good: "SEGNALE OTTIMO",
  cookies: "Esiste un giro salvato",
  cookies_action: "Vuoi riprendere il giro o iniziarne uno nuovo?",
  cookies_delete: "Inizia nuovo giro",
  cookies_maintain: "Riprendi il giro",
  unlock: 'Confermare lo sblocco?',
  unlock_subtitle: 'Lo sblocco dello schermo è riservato agli admin',
  areyouready: "Sei pronto per iniziare?",
  welcome: "BENVENUTO",
  beforestart: "Prima di visitare i luoghi di interesse clicca su Inizia, così l'app sara gia attiva e potrai goderti l'esperienza sonora.",
  map: "Mappa",
  info: "Info",
  mobile_only: "Questa App è  progettata per dispositivi mobili",
  screen_locked: "Schermata bloccata",
  swipe_left: "Scorri verso sinistra per sbloccare",
  admin_title: "Pannello amministratore",
  admin_close: "Chiudi",
  admin_test: "Test audio",
  admin_reset_hard: "Torna alla home",
  search_gps: "Ricerca GPS in corso",
  credits_title: "Suoni della Memoria",
  credits_desc: "Suoni della Memoria è un progetto interattivo che nasce come creazione all'interno del Progetto Memoria, un percorso iniziato negli anni 90 del XX secolo dal Comune di San Giuliano Terme e che ha l'obiettivo di preservare e rilanciare la memoria storica degli eventi e delle persone del territorio sangiulianese durante la Seconda Guerra Mondiale. Il Progetto Memoria è gestito dall'Associazione Teatri della Resistenza, che è il motore di questo periodo di modernizzazione del racconto della Memoria in collaborazione con l'Assessorato alla Memoria e gli uffici preposti. Suoni della Memoria è un'interazione sonora sui ricordi e sulle voci che hanno segnato il territorio di San Giuliano e che lo hanno reso quel che è oggi: un luogo della democrazia.",
  app_name: "Suoni della Memoria",
  how_it_works: "Come funziona quest'app?",
  app_explanation: "Per questa esperienza abbiamo creato degli “spot attivi”, che associano una posizione nello spazio ad un contenuto audio. La riproduzione dei contenuti è basata sulla tua posizione: il sistema localizza il punto in cui ti trovi e attiva (o disattiva) un contenuto nel momento in cui entri (o esci) dal suo raggio d'azione. Puoi monitorare la tua posizione e quella degli spot attivi dalla mappa: avvicinati e goditi l'ascolto!",
  project_by: "Un progetto di:",
  sponsored_by: "Patrocinato da:",
  sound_design_app_dev: "Sound design e sviluppo app:",
  narrator: "Testi e voce narrante:",
  translator: "Versione inglese:",
  already_visited_title: "Hai già visitato questa posizione di recente",
  already_visited_text: "Vuoi riascoltare il contenuto audio?",
  already_visited_confirm: "Si, ascolta",
  already_visited_dismiss: "No, chiudi",
};

export default it;
