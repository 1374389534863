import React from "react";

import { useTheme } from "../contexts/theme";

import {
  FormGroup,
  Stack,
  Typography,
  Switch,
  IconButton,
} from "@mui/material";

import DayIcon from "@mui/icons-material/WbSunny";

import NightIcon from "@mui/icons-material/Nightlight";

import String from "./String";

import palette from "../config/themes/palette";

export default function ThemeSwitcher() {

  const { theme, toggleTheme } = useTheme();
  
  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography color={theme !== "day" && "textSecondary"}>
          <String s="day" />
        </Typography>
        <Switch
          icon={<SwitchIcon theme={theme} />}
          checkedIcon={<SwitchIcon theme={theme} />}
          checked={theme === "night"}
          onChange={toggleTheme}
          color="primary"
        />
        <Typography color={theme !== "night" && "textSecondary"}>
          <String s="night" />
        </Typography>
      </Stack>
    </FormGroup>
  );
}

function SwitchIcon({ theme }) {
  return (
    <IconButton
      size="small"
      style={{
        background: theme === "day" ? palette.light_grey : palette.dark_grey,
      }}
    >
      {theme === "day" ? (
        <DayIcon style={{ fontSize: "15px" }} />
      ) : (
        <NightIcon style={{ fontSize: "15px" }} />
      )}
    </IconButton>
  );
}
