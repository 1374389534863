const palette = {
  white: "#FFFFFF",
  white_rgb: "255, 255, 255",
  institutional_blue: "#005595",
  glade_green: "#588157",
  green: "#32fd43",
  grey: "#A5A093",
  light_grey: "#F2EFEB",
  light_grey_rgb: "242, 239, 235",
  dark_grey: "#131C13",
  dark_grey_rgb: "88, 129, 87"
};

export default palette;
