import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";

import {
  Box,
  Select,
  MenuItem,
  Typography,
  FormControl,
  Button,
  Grid,
} from "@mui/material";

import Layout from "../components/Layout";

import Routes from "../components/Routes";

import Logo from "../components/Logo";

import ThemeSwitcher from "../components/ThemeSwitcher";

import { useLanguage } from "../contexts/language";

import String from "../components/String";

import Footer from "../components/Footer";

import AppVersion from "../components/AppVersion";

import BackIcon from "@mui/icons-material/ArrowRightAlt";

import Image from "../components/Image";

import test_sound from "../assets/audio/test_sound.mp3";

import { deleteCookie, getCookie } from "../utils/cookies";

import { useHistory } from "react-router-dom";

import geoConfig from "../config/geo/index"

// cookies deletion
const PATTERN_ID = 'exp1';

const deleteGeoXpCookie = () => deleteCookie(`geoxp-pattern-${PATTERN_ID}`);

const getGeoXpCookie = () => getCookie(`geoxp-pattern-${PATTERN_ID}`);

const gpsConfig = {
  enableHighAccuracy: geoConfig?.options?.enableHighAccuracy || true,
  maximumAge: geoConfig?.options?.maximumAge || 30000,
  timeout: geoConfig?.options?.timeout || 27000
}

const steps = [
  { path: "/boarding/1", component: Language },
  // { path: "/boarding/2", component: Headphones },
  { path: "/boarding/2", component: Audio },
  { path: "/boarding/3", component: Gps },
];

// checks cookies presence to show 4th boarding screen
const cookie = getGeoXpCookie();
if (cookie) {
  steps.push({ path: "/boarding/5", component: Cookies });
}

export default function Boarding() {

  const { language } = useLanguage();

  const views = boardingViews(steps, language);

  return (
    <Layout
      full
      textured
      top={
        <Box mt={"5vh"} textAlign="center">
          <Logo />
        </Box>
      }
      bottom={
        <Footer>
          <PoweredBy />
        </Footer>
      }
    >
      <AppVersion />
      <Routes views={views} />
    </Layout>
  );
}

function Language(p) {
  const { language, availableLanguages, setLanguage } = useLanguage();
  return (
    <BoardingStep
      title={<String s="select_lang" />}
      form={
        <FormControl variant="outlined" fullWidth>
          <Select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            {availableLanguages.map((l) => (
              <MenuItem value={l} key={l}>
                <String s="lang" l={l} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
      action={<String s="confirm" />}
      themeSwitcher
      {...p}
    />
  );
}

function Headphones(p) {
  return (
    <BoardingStep
      title={<String s="headset" />}
      image={"headphones"}
      action={<String s="continue" />}
      {...p}
    />
  );
}

function Audio(p) {
  return (
    <>
      <BoardingStep
        pretitle={<String s="raise_volume" />}
        title={<String s="sound" />}
        image={"phone"}
        action={<String s="yes_continue" />}
        {...p}
      />
      <audio src={test_sound} autoPlay loop style={{ display: "none" }} />
    </>
  );
}

const getGpsQuality = (accuracy) => {
  // bypass gps accuracy check
  if (window.gpsAccuracyCheckForced) return 3

  // gps quality index 0: no position, 1: bad, 2: average, 3: good
  if (accuracy <= geoConfig.options.accuracy * 0.2) return 3
  if (accuracy <= geoConfig.options.accuracy) return 2
  if (accuracy) return 1
  return 0
}

function Gps(p) {

  const [gpsStatus, setGpsStatus] = useState({ quality: 0, accuracy: undefined, error: undefined })
  const geoWatchSub = useRef()
  useEffect(() => {
    geoWatchSub.current = navigator.geolocation.watchPosition((position) => {
      console.log("position update", position)
      setGpsStatus({ error: undefined, accuracy: position.coords.accuracy, quality: getGpsQuality(position?.coords?.accuracy) })
    }, (error) => {
      console.error("geolocation error", error)
      if (error.code === 1) {
        setGpsStatus({ error: error.message, accuracy: undefined, quality: 0 })
      }
    }, gpsConfig);
    return () => { geoWatchSub.current && navigator.geolocation.clearWatch(geoWatchSub.current) };
  }, [])

  // override gps controls
  const forceGpsAccuracyCheck = () => {
    setGpsStatus({ error: undefined, accuracy: 10, quality: 3 });
    window.gpsAccuracyCheckForced = true;
  }
  window.forceGpsAccuracyCheck = forceGpsAccuracyCheck;

  return (
    <>
      <BoardingStep
        pretitle={<String s="wait_location" />}
        title={<String s="gps" />}
        image={"gps"}
        action={
          gpsStatus.error
            ? undefined
            : (gpsStatus.quality <= 1 ? <String s="wait" /> : <String s="continue" />)}
        disabled={gpsStatus.error || gpsStatus.quality <= 1}
        form={gpsStatus.error
          ? <>
            <Typography variant={"title"} mb={2}><String s="gps_error" /></Typography>
            <Typography style={{ fontSize: "10px" }}>
              {gpsStatus.error}
            </Typography>
          </>
          : <>
            {gpsStatus.quality === 1 ?
              <Typography color="glade_green" variant="title" mb={2}><String s="gps_quality_bad" /></Typography>
              : gpsStatus.quality === 2 ?
                <Typography color="#CAA53D" variant="title" mb={2}><String s="gps_quality_average" /></Typography>
                : gpsStatus.quality === 3 ?
                  <Typography color="green" variant="title" mb={2}><String s="gps_quality_good" /></Typography>
                  : null}
            {gpsStatus.accuracy ? <Typography style={{ fontSize: "10px" }}>
              Accuracy: {gpsStatus.accuracy.toFixed(2)}m
            </Typography> : null}
          </>}
        {...p}
      />
    </>
  );
}

function Cookies(p) {

  const history = useHistory();

  const deleteCookies = (e) => {
    e.preventDefault();
    deleteGeoXpCookie();
    console.log("geoXp cookies deleted!")
    history.push(p.next);
  }

  return (
    <BoardingStep
      title={<String s="cookies" />}
      text={<String s="cookies_action" />}
      action={<String s="cookies_maintain" />}
      auxAction={<String s="cookies_delete" />}
      auxActionClick={deleteCookies}
      {...p}
    />
  );

}

const boardingViews = (steps, language) =>
  steps.map(({ component: Component, path }, i) => {
    const next = steps[i + 1] ? steps[i + 1].path : `/tour?lang=${language}`;
    const back = steps[i - 1] ? steps[i - 1].path : null;
    return {
      path,
      render: (props) => (
        <Component
          steps={steps}
          step={i + 1}
          next={next}
          back={back}
          {...props}
        />
      ),
      exact: true,
    };
  });

function BoardingStep({
  title,
  pretitle,
  text,
  image,
  media,
  form,
  action,
  auxAction,
  auxActionClick,
  next,
  disabled,
  themeSwitcher,
  ...p
}) {
  return (
    <Layout>
      <Box textAlign="center" mb={"5vh"} width="90%">
        {pretitle && (
          <Typography variant={"pretitle"} mb={2}>
            {pretitle}
          </Typography>
        )}
        <Typography variant={"title"} mb={2}>
          {title}
        </Typography>
        {text && (
          <Typography variant={"text"} mb={2}>
            {text}
          </Typography>
        )}
        {media}
        <Box mb={3} mt={3}>
          {image && <Image i={image} style={{ height: "15vh" }} />}
          {form}
        </Box>
        {action && <Box mb={4} mt={4}>
          <Button
            color="secondary"
            variant="contained"
            component={Link}
            to={next}
            disabled={disabled}
            fullWidth
          >
            {action}
          </Button>
        </Box>}
        {auxAction && auxActionClick ?
          <Box>
            <Button
              color="secondary"
              variant="contained"
              mt={2}
              disabled={disabled}
              fullWidth
              // component={Link}
              // to={next}
              onClick={(e) => auxActionClick(e)}
            >
              {auxAction}
            </Button>
          </Box> : null}
      </Box>
      <StepNav {...p} />
      {themeSwitcher && <ThemeSwitcher />}
    </Layout>
  );
}

function StepNav({ step, steps, back }) {
  return (
    <Grid container alignItems="center">
      <Grid item xs={3} align="center">
        {back && (
          <Link to={back}>
            <Typography fontSize={"4vw"} color="textSecondary">
              <BackIcon
                style={{ transform: "rotate(-180deg)", fontSize: "40px" }}
              />
            </Typography>
          </Link>
        )}
      </Grid>
      <Grid item xs={6} align="center">
        <Typography fontSize={"6vw"} color="textSecondary">
          {step}/{steps.length}
        </Typography>
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
}

function PoweredBy() {
  return <Typography variant="text">Powered by mezzoforte</Typography>;
}
