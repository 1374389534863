import React, { useState } from "react";

import themes from "../config/themes";

import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";

const ThemeContext = React.createContext(null);

export default function Theme({ children }) {

  const [theme, setTheme] = useState("day");

  const toggleTheme = () => setTheme(theme === "day" ? "night" : "day");

  let { styles, image_dir } = themes[theme];

  styles = createTheme(styles);
  
  return (
    <ThemeContext.Provider value={{ theme, styles, image_dir, toggleTheme, setTheme }}>
      <ThemeProvider theme={styles}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export const useTheme = () => React.useContext(ThemeContext);
