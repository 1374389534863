import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback
} from "react";

import GeoXp from "@mezzo-forte/geoxp";

import geo from "../config/geo";

import audio from "../config/audio";

import experience from "../config/experience";

import { getCookie } from "../utils/cookies";

import { useLanguage } from "./language";

const ExperienceContext = React.createContext(null);

const config = { geo, audio: audio(), experience };

const downloadConfig = () => {
  const jsonConfig = JSON.stringify(config);
  const blob = new Blob([jsonConfig], { type: "text/json" });
  const link = document.createElement("a");
  link.download = 'suoni-memoria-geoXp-config.json';
  link.href = window.URL.createObjectURL(blob);
  link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");
  const evt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  link.dispatchEvent(evt);
  link.remove();
};

window.downloadConfig = downloadConfig;

const PATTERN_ID = 'memoria-exp';

// const DEFAULT_PATTERN_COOKIE_PREFIX = 'geoxp-pattern';

const useOnInit = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export default function Experience({ children }) {

  const geoXp = useMemo(() => {
    console.info('geoXp instance created');
    const instance = new GeoXp(config)
    window.geoXp = instance;
    return instance;
  }, []);

  const simulateSpot = useCallback((spotId) => {

    const spot = experience
      .patterns[0]
      .spots
      .find((el) => el.id === spotId);

    if (!spot) {
      console.warn("spot not found", spotId);
    }

    const posId = spot.position;

    const position = geo.positions.find((pos) => pos.id === posId);

    if (!position) {
      console.warn("position not found", posId);
    }

    console.info("simultaing spot", spot, "with position", position);

    geoXp.updateGeolocation({
      coords: {
        latitude: position.lat,
        longitude: position.lon,
        accuracy: 10,
      },
    });
  }, [geoXp]);

  window.simulateSpot = simulateSpot;

  const onInit = useOnInit();

  const { language } = useLanguage();

  const [position, setPosition] = useState();
  const [active, setActive] = useState();
  const [player, setPlayer] = useState();
  const [spotIndex, setSpotIndex] = useState(0);
  const [alreadyVisitedId, setAlreadyVisitedId] = useState(null);

  const reload = useCallback((_config) => {
    _config
      ? geoXp.reload(_config)
      : geoXp.reload(config);
  }, [geoXp]);

  const destroy = () => geoXp.destroy();

  const unlock = () => geoXp.unlock();

  const replayVisited = useCallback(() => {
    alreadyVisitedId
      ? geoXp.replaySpot(alreadyVisitedId)
      : geoXp.replaySpot();
  }, [geoXp, alreadyVisitedId]);

  const getGeoXpCookie = (patternName) => getCookie(`geoxp-pattern-${patternName}`);

  const test = () => geoXp.audio.test();

  useEffect(() => {
    // listeners
    geoXp.on("position", (position) => {
      console.info('position event - position', position);
      setPosition(position);
    });

    geoXp.on("active", (spot) => {
      console.info('active event - spot', spot);
    });

    geoXp.on("visited", (spot) => {
      console.info('visited event - spot', spot);
      setAlreadyVisitedId(spot?.id);
    });

    geoXp.on("outgoing", (spot) => {
      console.info('outgoing event - spot', spot);
    });

    geoXp.on("play", (audio) => {
      console.info('play event - audio', audio);
      setPlayer(audio);
      setActive(audio.spot);
      setAlreadyVisitedId(null);
    });

    geoXp.on("stop", (audio) => {
      console.info('stop event - audio', audio);
      if (geoXp.hasAudioPlaying()) {
        return;
      }
      setActive(null);
      setPlayer(null);
      setAlreadyVisitedId(null);
    });

    // prev playerd spots (cookies)
    const geoXpCookie = getGeoXpCookie(PATTERN_ID);
    if (geoXpCookie) {
      const visitedSpots = geoXp.getVisitedSpots(PATTERN_ID);
      const visitedSpotCount = (visitedSpots && Array.isArray(visitedSpots)) ? visitedSpots.length : 0;
      setSpotIndex(i => i + visitedSpotCount);
    }

    return () => geoXp.destroy();

  }, [geoXp]);

  useEffect(() => {
    if (!onInit) {
      const _config = { ...config, audio: audio(language) };
      reload(_config);
    }
  }, [language, onInit, reload]);

  const spots = geo.positions;

  useEffect(() => {
    if (active) {
      setSpotIndex((i) => i + 1);
    }
  }, [active]);

  return (
    <ExperienceContext.Provider
      value={{
        spots,
        spotIndex,
        position,
        active,
        player,
        getGeoXpCookie,
        reload,
        destroy,
        unlock,
        test,
        alreadyVisitedId,
        setAlreadyVisitedId,
        replayVisited,
      }}
    >
      {children}
    </ExperienceContext.Provider>
  );
}

export const useExperience = () => React.useContext(ExperienceContext);

